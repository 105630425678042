<template>

    <div>
        <v-container id="dashboard" fluid tag="section">
            <v-text-field class="mt-4" label="اكتب للبحث" outlined append-icon="mdi-magnify" v-model="search">
            </v-text-field>
            <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title> {{ $t("about") }} </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="890px">
                          <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                                    v-on="on">
                                    {{ $t("add_new") }}
                                </v-btn>
                            </template>
                            <v-stepper v-model="e1">
                             

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-card class="mb-12" flat>

                                            <v-form ref="form" v-model="valid">

                                               

                                                <v-card-text>
                                                    <v-container>

                                                        <v-row>

                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.title.ar" :rules="messageRules"  required
                                                                    :label="$t('datatable.title')+' '+$t('arabic')"
                                                                    outlined>
                                                                </v-text-field>
                                                            </v-col>

                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.title.en" :rules="messageRules"  required
                                                                    :label="$t('datatable.title')+' '+$t('en')"
                                                                    outlined>
                                                                </v-text-field>
                                                            </v-col>

                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.about.ar" :rules="messageRules"  required
                                                                    :label="$t('datatable.about')+' '+$t('arabic')"
                                                                    outlined>
                                                                </v-textarea>
                                                            </v-col>




                                                            <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-textarea v-model="editedItem.about.en" :rules="messageRules"  required
                                                                    :label="$t('datatable.about')+' '+$t('en')"
                                                                    outlined>
                                                                </v-textarea>
                                                            </v-col>

                                                             <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.views" :rules="messageRules"  required
                                                                    :label="$t('datatable.views')"
                                                                    outlined>
                                                                </v-text-field>
                                                            </v-col> -->
                                                        


                                                            
                                                            <!-- <v-col class="py-0" cols="6" sm="12" md="6">
                                                                <v-text-field :label="$t('Drawer.choose_img')"
                                                                    @click='pickImg' v-model='imageName'
                                                                    prepend-icon='fas fa-paperclip'>
                                                                </v-text-field>
                                                                <input type="file" style="display: none" ref="image"
                                                                    accept="image/*" @change="onFilePicked">

                                                            </v-col> -->



                                                            <!-- <v-col class="py-0" cols="6" sm="6" md="6">
                                                                <v-img :src="imageUrl" height="150"
                                                                    :lazy-src="thumb_small" v-if="imageUrl" />
                                                            </v-col> -->

                             

                                                        </v-row>

                                                    </v-container>
                                                </v-card-text>



                                            </v-form>

                                        </v-card>

                                        <v-btn color="primary" :loading="loadSave" @click="save()">
                                            {{$t("save")}}
                                        </v-btn>

                                        <v-btn text @click="close()">
                                            {{$t("close")}}
                                        </v-btn>
                                    </v-stepper-content>

                                </v-stepper-items>
                            </v-stepper>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">





                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn text @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                               {{ $t("edit") }}</v-btn>


                        </template>
                        <span>{{ $t("edit") }} </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text @click="deleteItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                                {{$t('Delete')}}</v-btn>

                        </template>
                        <span>{{$t('Delete')}}</span>
                    </v-tooltip>



                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        data() {
            return {
                desserts: [],
                dialog: false,
                loadSave: false,
                editedIndex:-1,
                file_name: null,
                x:this.$i18n.locale,

                e1: 1,
                imagesUrl: '',
                imageName: '',
                show_loading: false,
                imageUrl: '',
                img_old: '',
                imageFile: '',
                imagesName: ' ',
                editedIndex: -1,
                editedItem: {
                    images:[],
                    image: {
                        image_name: ''
                    },
                    id: "",
                    about: {
                        ar: "",
                        en: ""
                    },
                    title: {
                        ar: "",
                        en: ""
                    },
                    views:"",

                },
                items: [

                ],
                
                right: null,
                vvv: {},
                messageRules:[
                v => !!v || this.$t("valMessage")
                ],
            }
        },
        watch:{
            '$i18n.locale': function(newVal, oldVal) {
            
            this.x=newVal;
            this.get();  
  },  },
        methods: {
            get(){
                return this.headers= [{
                        text: '#ID',
                        align: "start",
                        value: "id"
                    },

                    {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value: this.x == 'en' ? "title.en" : "title.ar"
                    },
                                       {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ];
            },
            validate () {
        valid: true;
        },
                pickImg() {
                this.$refs.image.click()
            },

           onFilePicked(e) {

                const files = e.target.files

                if (files[0] !== undefined) {

                    this.imageName = files[0].name

                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }


                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {

                        this.imageUrl = fr.result
                        this.imageFile = files[0]


                    })
                } else {
                   


                }
            },

            editItem(item) {

                // this.e1 = 1;
                this.editedIndex = this.desserts.indexOf(item);
                this.$store.dispatch("art_uploudess", item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
                this.img_old = ''
                this.imageName = ''
                this.imageFile = null
                this.imageUrl = ''
               this.img_old = this.editedItem.imageUrl;
                // this.imageUrl = this.Url + '/art_img/thumb_new/' + this.img_old;
            },
            close() {
                 this.dialog = false;

               this.loadSave=false;
                this.img_old = '',
                this.imageName = '',
                this.imageFile = '',
                this.imageUrl = '' ,
                this.editedItem= {
                    images:[],
                    image: {
                        image_name: ''
                    },
                    id: "",
                    about: {
                        ar: "",
                        en: ""
                    },
                    title: {
                        ar: "",
                        en: ""
                    },
                    views:"",

                },
                this.$refs.form.resetValidation();
    
               
                this.$store.dispatch("art_uploudess", this.vvv);
                this.$store.dispatch("art_uploudess", this.vvv);
              
              
            },
            initialize() {
                this.loading = true;
                axios.get("about", {
                    headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            authorizations: "Bearer " + this.$store.state.AdminInfo.token
                        } 
                    })
                    .then(res => {
                    
                        this.loading = false;
                        this.desserts = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
        
            deleteItem(item) {

                Swal.fire({
                    title: this.$t('sure_process'),
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then(result => {
                    if (result.value) {
                        axios.delete("about/" + item.id, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                }
                            })
                            .then(() => {
                                this.initialize();
                                this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");

                            })
                            .catch(() => {
                                this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                            });
                    }
                });
            },
          save() {
             if(this.$refs.form.validate()){


                if (this.imageFile == '') {
                    this.$swal.fire({
                        title: "يجب اختيار صوره",
                        text: "",
                        icon: "error",
                        confirmButtonText: "اغلاق",
                    });

                } else {
                    this.loadSave = true;
                    if (this.editedIndex > -1) {

                        var update = {
                                  
                            translations: [
                                { about:this.editedItem.about.ar },
                                { about:this.editedItem.about.en },
                                { title:this.editedItem.title.ar },
                                { title:this.editedItem.title.en }
                            ],
                            views:this.editedItem.views

                        }
                        this.axios
                            .put("aboutUpdate/" + this.editedItem.id,update, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                },
                            })
                            .then(() => {

                                this.$refs.form.resetValidation();

                           
                                if (this.imageFile != null) {
                                    let formData = new FormData();
                                    // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                    formData.append('photo', this.imageFile);
                                    axios.post('about/upload/' + this.editedItem.id,
                                        formData, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                                Accept: "application/json",
                                                authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                            } 
                                        }
                                    )
                                    this.img_old = ''
                                    this.imageName = ''
                                    this.imageFile = ''
                                    this.imageUrl = ''
                                }
                         
                                this.loadSave=false;
                                this.initialize();
                                this.close();

                                this.$swal.fire({
                                    title: "تم تعديل ",
                                    text: "",
                                    icon: "success",
                                    confirmButtonText: "اغلاق",
                                });
                            })
                            .catch(() => {
                                this.loadSave = false;
                                this.$swal.fire({
                                    title: "تاكد من ملى المعلومات",
                                    text: "",
                                    icon: "error",
                                    confirmButtonText: "اغلاق",
                                });
                            });
                    } else {

                          var savedate = {
                         
                            translations: [{

                                  about:this.editedItem.about.ar,
                                              
                                    },
                                {
                                      about:this.editedItem.about.en,
                                
                                }],
                            views : this.editedItem.views

                        }


                        this.axios
                            .post("about", savedate, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                },
                            })
                            .then((res) => {
                           
                            this.$refs.form.resetValidation();

                                this.editedItem = res.data.data;
                                this.editedIndex = -1;

                                if (this.imageFile != null) {
                                    let formData = new FormData();
                                    // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                    formData.append('photo', this.imageFile);
                                    axios.post('about/upload/' + this.editedItem.id,
                                            formData, {
                                                headers: {
                                    'Content-Type': 'multipart/form-data',
                            Accept: "application/json",
                            authorizations: "Bearer " + this.$store.state.AdminInfo.token
                        } 
                                            }
                                        )
                                        .then(response => {
                                            response

                                            this.e1 = 2
                                            this.loadSave = false;
                                            this.img_old = ''
                                            this.imageName = ''
                                            this.imageFile=''
                                            
                                            // }
                                        })
                                    this.imageName = '',
                                         this.loadSave = false;
                                         this.$swal.fire({
                                                title: 'تم رفع ',
                                                text: "",
                                                icon: "success",
                                                confirmButtonText: this.$t('close'),
                                            });
                            this.initialize();
                                    this.close();
                                }
                            })
                            .catch((err) => {
                                err
                            });
                    }
                }
                }

            },
           
        },
            computed: {
      formTitle() {
        return this.editedIndex === -1 ? this.$t('add_new')  :  this.$t('update');
          
      },
    },
        created() {
            this.initialize();
            this.get();

        },

    }
</script>

<style>
.v-overlay--active {
    z-index: 0 !important;
}

</style>